import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import zorgbandfullImg from "../../images/projectsfull/zorgband.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Zorgband</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="Zorgband"
              projectfulldescription="For care organization Zorgband EntityOne developed a new corporate identity and a simple user friendly Drupal 8 content website.I was responsible for logo design and look of the website."
              projectclient="Zorgband"
              projectrole="Web Design"
              projectdate="2019"
              projectfullimg={<div className="project--img--inner"><img data-src={zorgbandfullImg} className="project--img lazyload" alt="Zorgband" /></div>}
              projectnextlink="/projects/woonhaven"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;